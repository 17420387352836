
import HiddenHorizontalContainer from '@/components/HiddenHorizontalContainer.vue';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { SnowplowContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { AppDirection } from '@/enums/app-direction';

import { RoutingState } from '@/stores/routing.store';

const Routing = namespace('routing');
const Meta = namespace('meta');

@Component({
	name: 'HiddenHorizontalScrollbar',
	components: { HiddenHorizontalContainer, FontAwesomeIcon },
})
export default class HiddenHorizontalScrollbar extends Vue {
	@Prop({ default: () => ({}) }) additionalItemStyles: object;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Prop({ default: 0 }) startScrollPosition: number;
	@Prop({ default: false }) resetScrollOnChange: boolean;
	@Prop({ default: false }) shift: boolean;
	@Prop({ default: true }) allItemsLoaded: boolean;
	@Prop({ default: false }) emitOnScroll: boolean;
	@Prop({ default: false }) disabled: boolean;
	@Prop({ default: false }) fadedEdge: boolean;
	@Prop({ default: false }) hideNav: boolean;
	@Prop({ default: false }) forceShowNav: boolean;
	@Prop({ default: 0 }) scrollModfier: number;
	@Prop({ default: false }) blockMobileSwipe: boolean;
	@Prop({ default: false }) altNav: boolean;
	@Prop({ default: 0 }) itemAmount: number;

	@Routing.State activeRoute: RoutingState['activeRoute'];
	@Routing.State lastRoute: RoutingState['lastRoute'];
	@Meta.Getter appDirection: AppDirection;

	@Ref() scroller: Element;

	isScrollBarVisible = false;
	curScrollPos = 0;
	savedScrollPos = 0;
	isAtEnd = false;
	selectedItem = 0;

	get itemStyle() {
		return this.additionalItemStyles;
	}

	get isAtStart(): boolean {
		return this.curScrollPos === 0;
	}

	get isRTL(): boolean {
		return this.appDirection === AppDirection.RTL;
	}

	getScrollPosition(scrollLeftAmount: number) {
		return this.isRTL ? -scrollLeftAmount : scrollLeftAmount;
	}

	deactivated() {
		if (Math.abs(this.curScrollPos) > 0) {
			this.savedScrollPos = this.curScrollPos;
		}
	}

	activated() {
		if (Math.abs(this.savedScrollPos) > 0) {
			this.scroller.scrollTo({ top: 0, left: this.savedScrollPos });
			this.selectedItem = 0;
		}
	}

	mounted() {
		this.checkScrollable();
		if (this.startScrollPosition) {
			this.scroller.scrollTo({ top: 0, left: this.getScrollPosition(this.startScrollPosition) });
		}
	}

	@Watch('activeRoute')
	onRouteChange() {
		if (
			this.resetScrollOnChange &&
			this.activeRoute?.meta?.tab === 'detail' &&
			this.lastRoute?.params.title !== this.activeRoute?.params.title
		) {
			this.resetScrollPosition();
		}
	}

	scrolled() {
		if (!this.scroller) return;

		this.curScrollPos = this.scroller.scrollLeft;

		// Calculate the scroll progress
		const progress = Math.round(
			(Math.abs(this.scroller.scrollLeft) / (this.scroller.scrollWidth - this.scroller.clientWidth)) * 100
		);
		if (
			progress < 95 ||
			(this.scroller.scrollWidth === 0 &&
				this.scroller.clientWidth === 0 &&
				this.scroller.clientHeight === 0 &&
				this.allItemsLoaded)
		) {
			this.isAtEnd = false;
		} else {
			this.isAtEnd = true;
		}

		if (progress > 40) {
			this.$emit('onScrollRight');
		}
		if (this.isAtEnd && !this.allItemsLoaded) {
			this.$emit('onScrollRight');
			this.isAtEnd = false;
		}
	}

	scrollToEnd() {
		this.scrollBy(-(this.scroller.clientWidth - 90 + this.scrollModfier));
		this.trackScroll('left');
	}

	scrollToStart() {
		this.scrollBy(this.scroller.clientWidth - 90 + this.scrollModfier);
		this.trackScroll('right');
	}

	scrollBy(n: number) {
		if (this.blockMobileSwipe) {
			if (!(this.selectedItem === 0 && n < 0) || !(this.selectedItem === this.itemAmount - 1 && n > 0)) {
				this.scroller
					.querySelectorAll('.swiper-item')
					[n < 0 ? --this.selectedItem : ++this.selectedItem]?.scrollIntoView({
						behavior: 'smooth',
						block: 'nearest',
						inline: 'center',
					});
			}

			return;
		}
		this.scroller.scrollBy({ top: 0, left: this.getScrollPosition(n), behavior: 'smooth' });
	}

	resetScrollPosition() {
		if (!this.scroller) return;
		this.scroller.scrollTo({ top: 0, left: 0, behavior: 'auto' });
		this.selectedItem = 0;
	}

	trackScroll(label: 'left' | 'right') {
		if (this.emitOnScroll) {
			this.$emit('scrollbarClicked', label);
		} else {
			TrackingHelper.trackEvent(
				'userinteraction',
				{
					action: 'horizontal_scroller_clicked',
					label,
				},
				this.additionalContexts
			);
		}
	}

	handleMutations(mutationsList: MutationRecord[]) {
		for (const mutation of mutationsList) {
			if (mutation.type === 'childList') {
				// Check scrollbar visibility when mutations occur
				this.isScrollBarVisible = this.isHorizontalScrollBarVisible(this.scroller);
			}
		}
	}

	isHorizontalScrollBarVisible(element: Element) {
		return element.scrollWidth > element.clientWidth;
	}

	// this function checks if the scrollbar is visible
	checkScrollable() {
		this.isScrollBarVisible = this.isHorizontalScrollBarVisible(this.scroller);
		const observer = new MutationObserver(this.handleMutations);
		// Start observing the target element for mutations
		observer.observe(this.scroller, { childList: true, subtree: true });
	}
}
