
import Vue, { CreateElement, RenderContext } from 'vue';

import { webpFormat } from '@/components/picture/ImageSources';

import { createThumborFilter, ThumborImage } from '@/filters/thumbor';
import type Provider from '@/interfaces/provider';

const PROVIDER_ICON_DEFAULT_ICON_URL = 'https://static.justwatch.com/static/compile_jw/assets/jw-icon-badge.png';
const PROVIDER_FALLBACK_NAME = 'JustWatch';

const renderPictureComponent = ({
	h,
	contextData,
	providerName,
	imageUrl,
	webpImageUrl,
	providerId,
	lazy,
}: {
	h: CreateElement;
	providerName: string;
	imageUrl: string;
	webpImageUrl?: string;
	providerId?: number;
	lazy?: boolean;
	contextData: any;
}) => {
	let webpElement: JSX.Element | null = null;
	if (webpImageUrl) {
		webpElement = (
			<source
				type={webpFormat.type}
				media="(min-width: 0px)"
				srcset={lazy ? '' : webpImageUrl}
				data-srcset={lazy ? webpImageUrl : ''}
			/>
		);
	}

	const transparentBase64 = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
	const imgElement = (
		<img
			class={`${webpElement ? '' : 'provider-icon'} ${lazy ? 'lazyload' : ''}`}
			alt={providerName}
			title={providerName}
			data-sizes={lazy ? 'auto' : undefined}
			src={lazy ? transparentBase64 : imageUrl}
			data-src={lazy ? imageUrl : undefined}
			{...contextData}
		/>
	);

	return webpElement ? (
		<picture class="provider-icon">
			{webpElement}
			{imgElement}
		</picture>
	) : (
		imgElement
	);
};

export default Vue.extend({
	name: 'ProviderIcon',
	functional: true,
	props: {
		providerId: { type: Number },
		lazy: { type: Boolean, default: true },
		size: {
			type: String,
			default: 'provider_icon_small',
			validator: (size: string) =>
				['provider_icon', 'provider_icon_small', 'provider_icon_button'].includes(size),
		},
		imgUrl: { type: String },
		iconUrl: { type: String },
		providersById: { type: Object },
	},
	render(h: CreateElement, ctx: RenderContext<Record<string, any>>) {
		const { imgUrl, lazy, providerId, size, iconUrl } = ctx.props as {
			imgUrl?: string;
			iconUrl?: string;
			lazy: boolean;
			providerId?: number;
			size: keyof ThumborImage<any>;
		};

		if (!providerId) {
			throw new Error('providerId has to be defined');
		}

		if (imgUrl) {
			return renderPictureComponent({
				h,
				contextData: ctx.data,
				imageUrl: `https://images.justwatch.com${imgUrl}`,
				providerName: PROVIDER_FALLBACK_NAME,
				providerId,
				lazy,
			});
		}

		const provider = ((ctx.props as any).providersById as undefined | Record<string, Provider>)
			? (ctx.props as any).providersById[providerId]
			: (ctx.parent.$store.getters['constant/allProvidersById'][providerId] as Provider);
		const providerClearName = provider ? provider.clearName : PROVIDER_FALLBACK_NAME;
		let iconUrlRaw = PROVIDER_ICON_DEFAULT_ICON_URL;
		if (iconUrl) {
			iconUrlRaw = iconUrl;
		} else if (provider) {
			iconUrlRaw = provider.icon;
		}
		if (!iconUrlRaw.startsWith('/') && !iconUrlRaw.startsWith('http')) iconUrlRaw = '/'.concat(iconUrlRaw);

		const getImageUrl = (extension?: string): string =>
			createThumborFilter(ctx.parent.$store.state)({
				url: iconUrlRaw,
				imageType: size,
				extension: extension,
				criticalImage: !lazy,
				slug: 'icon',
			});

		return renderPictureComponent({
			h,
			contextData: ctx.data,
			imageUrl: getImageUrl(),
			webpImageUrl: getImageUrl(webpFormat.extension),
			providerName: providerClearName,
			providerId: providerId,
			lazy,
		});
	},
});
