
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import type { ThumborImage } from '@/filters/thumbor';
import type { ImageProp } from '@/helpers/picture-comp-helper';

import PictureCompHelper from '@/helpers/picture-comp-helper';

export enum PictureCompEvent {
	LOAD = 'load',
}

@Component({
	name: 'PictureComp',
	components: {},
})
export default class PictureComp extends Vue {
	@Prop({ type: String, default: '' }) readonly alt!: string;
	@Prop({ type: String, default: '' }) readonly imageProfile!: keyof ThumborImage<any>;
	@Prop({ type: String, default: '' }) readonly imageUrl!: string;
	@Prop({ type: Boolean, default: false }) readonly fadeIn!: boolean;
	@Prop({ type: String, default: '' }) readonly slug!: string;
	@Prop({ type: Boolean, default: true }) readonly lazy!: boolean;
	@Prop({ type: String, default: 'eager' }) readonly loading!: 'eager' | 'lazy';
	@Prop({ default: null }) sponsoredRecImages: { imageUrl: string; sourceSets: any };
	@Prop({ default: () => ['webp', 'jpeg'] }) readonly imagePropFilter!: string[];
	@Prop({ default: undefined }) readonly imageProps: Record<string, ImageProp>;
	@Prop({ default: false }) objectPositionTop: boolean;

	sourceSets: ReturnType<typeof PictureCompHelper.getSourceSetImages> | null = null;
	defaultImageUrl: ReturnType<typeof PictureCompHelper.getDefaultImageUrl> | null = null;

	get formattedSourceSets() {
		if (this.sponsoredRecImages?.sourceSets) return this.sponsoredRecImages.sourceSets;
		return this.sourceSets;
	}

	get formattedDefaultImageUrl() {
		if (this.sponsoredRecImages?.imageUrl) return this.sponsoredRecImages.imageUrl;
		return this.defaultImageUrl;
	}

	initSourceSets() {
		const isFirstPageView = this.$store.state.tracking.pagesVisited <= 1;
		const criticalImage = !this.lazy && isFirstPageView;

		this.sourceSets = PictureCompHelper.getSourceSetImages(
			this.imageUrl,
			this.imageProfile,
			this.slug,
			this.$store.state,
			this.imagePropFilter,
			criticalImage,
			this.imageProps
		);

		this.defaultImageUrl = PictureCompHelper.getDefaultImageUrl(
			this.imageUrl,
			this.imageProfile,
			this.slug,
			this.$store.state,
			criticalImage
		);
	}

	@Emit(PictureCompEvent.LOAD)
	emitLoad() {}

	@Watch('imageUrl', { immediate: true })
	onImageChange() {
		if (this.imageUrl == null) return;
		this.initSourceSets();
	}
}
