import { toValue } from '@vueuse/core';
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useMutation } from '@/helpers/composables/useApollo';

import { Platform, SraEventAction } from '@/@types/graphql-types';
import { ApplicationContext as applicationContext, TrackingAppId as appId } from '@/helpers/tracking/tracking-helper';
import { getUtmSearchParams } from '@/helpers/composables/useArbitrage';

import { useLanguageStore, useRoutingStore } from '@/helpers/composables/useStores';

import {
	TrackSraEventDocument,
	TrackSraEventMutation,
	TrackSraEventMutationVariables,
} from '@/graphql/mutation/TrackSraEvent.mutation';
import type { MaybeRefOrGetter } from '@vueuse/core';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

interface TrackSraArguments {
	action: SraEventAction;
	sponsoredAd: MaybeRefOrGetter<{ bidId: string }>;
	jwEntityID?: string;
	position?: number;
	progress?: number;
}

const DOMAIN = `https://www.${JW_CONFIG.DOMAIN}` as const;

export function useBackendTracking() {
	const route = useRoute();
	const { currentPageType } = useRoutingStore();
	const { country, language } = useLanguageStore();

	const pageUrl = computed(() => {
		const params = getUtmSearchParams();
		const url = new URL(route.fullPath, DOMAIN);

		if (params) params.forEach((value, key) => url.searchParams.set(key, value));

		return url.href;
	});

	function trackSraEvent({ action, sponsoredAd, jwEntityID, position, progress }: TrackSraArguments) {
		if (currentPageType.value === null) console.error('Empty Page Type, action: ', action);

		const bidId = toValue(sponsoredAd).bidId;

		const { mutate, onError } = useMutation<TrackSraEventMutation, TrackSraEventMutationVariables>(
			TrackSraEventDocument,
			{
				variables: {
					input: {
						bidId,
						action,
						sraInput: {
							appId,
							applicationContext,
							country: country.value,
							language: language.value,
							pageType: currentPageType.value,
							platform: Platform.Web,
						},
						jwEntityID,
						position,
						progress,
						pageUrl: pageUrl.value,
					},
				},
			}
		);

		mutate();

		onError(error =>
			captureMessageForSentry(
				'[GraphQL Track SRA Event]:',
				{ error, where: 'Composable: useBackendTracking' },
				'error'
			)
		);
	}
	return { trackSraEvent };
}
