
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'HiddenHorizontalContainer',
})
export default class HiddenHorizontalContainer extends Vue {
	mounted() {
		this.$emit('containerMounted');
	}
}
