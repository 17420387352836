import { toValue, type MaybeRefOrGetter, type UseIntersectionObserverOptions } from '@vueuse/core';
import Vue, { getCurrentInstance } from 'vue';

import VueObserveVisibility from 'vue-observe-visibility';

import type { TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';
import type { SnowplowContext } from '@/helpers/tracking/providers/snowplow-contexts';

import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

Vue.use(VueObserveVisibility);

export interface VueObserveVisibilityOptions {
	callback?: (isVisible: boolean, entry: IntersectionObserverEntry) => void;
	once?: boolean;
	throttle?: number;
	throttleOptions?: {
		leading?: 'visible' | 'hidden' | 'both';
	};
	intersection?: UseIntersectionObserverOptions;
}

export interface VisibilityChangedPayload<T> {
	element: T;
	isVisible: boolean;
}

export type OnIntersectionCallback<T> = (payload: VisibilityChangedPayload<T>) => void;

/**
 * According to MRC, a display ad will be considered “viewable”
 * if 50% of the ad creative is visible for at least one
 * second in the viewable space of the browser.
 */
export const mrcObservableOptions: VueObserveVisibilityOptions = {
	once: false,
	throttle: 1000,
	intersection: {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.5, // 50% visibility of each element
	},
} as const;

const properties: TrackingProviderPropertiesInterface = { action: 'impression', nonInteraction: true } as const;

/** Options to initialise the generic batch impression tracker for a category. */
interface UseBatchImpressionTrackingOptions<T> {
	/**
	 * Tracking category for the impression events.
	 * Contexts will be grouped according to this.
	 *
	 * e.g. you can group them by 'offer' impression and then further group them on entityId like: `offer_tm3959302`.
	 * this way, we won't mix title contexts with other impression batches.
	 */
	category: string;

	/**
	 * Transform the impressed elements into one or more tracking contexts.
	 * Supports two-dimensional arrays if you want 2+ contexts per element.
	 */
	toContext: (element: T) => SnowplowContext | SnowplowContext[];
	onVisibilityCallback?: (element: T) => void;
}

/** Options for category specific impression tracking composables.  */
export interface BatchImpressionTrackingOptions {
	/** Manage visibility intersection, thresholds, and more. */
	observableOptions?: VueObserveVisibilityOptions;

	/**
	 * How often (in ms) to send the batched impression events.
	 * @default 1000
	 */
	trackingInterval?: MaybeRefOrGetter<number>;

	/** Extra contexts to include in the sent impression event. */
	additionalContexts?: MaybeRefOrGetter<SnowplowContext[]>;

	// in case we switch offer impressions of different titles, we need to differentiate those
	groupId?: string;
}

// queue explanation:
// [CATEGORY, GROUP_ID, ELEMENT_CONTEXT, [ADDITIONAL_CONTEXTS]]
// - CATEGORY: category string for trackEvent category
// - GROUP_ID: further grouping of the batches, e.g. when using different titles
// - CONTEXT_ELEMENT: the element that has been seen
// - ADDITIONAL_CONTEXTS: de-duplicated contexts that will be sent once along with the CATEGORY
//
// e.g. ['offer', 'tm5333', OfferContext, [TitleContext]]
const queues = [] as [string, string, SnowplowContext | undefined, SnowplowContext[]][];

type GroupImpression = {
	category: string;
	elements: Map<SnowplowContext, SnowplowContext>;
	additionalContexts: Map<SnowplowContext, SnowplowContext>;
};

// we need to create 1 persistent timer that can be used all the time.
// this way we don't have to re-create it and handle asyncness problems.
setInterval(() => {
	// `groups` will be re-created every second.
	// it's derived from `queues` and is immutable. this way we shouldn't run into async problems.
	const groups = queues.reduce((root, item) => {
		const [category, groupId, element, additionalContexts] = item;
		const id = category + (groupId ? `_${groupId}` : '');
		if (!(id in root)) {
			// create group entry if it hasn't been created yet
			root[id] = { category, elements: new Map(), additionalContexts: new Map() };
		}

		// de-duplicate elements
		if (element) {
			root[id].elements.set(element, element);
		}

		// de-duplicate additionalContexts
		(toValue(additionalContexts) || []).forEach(context => root[id].additionalContexts.set(context, context));

		return root;
	}, {} as Record<string, GroupImpression>);
	// reset queue
	queues.splice(0, queues.length);
	// send impressions in grouped batches
	Object.values(groups).forEach(group => sendGroupImpressionEvent(group));
}, 1000);

function addQueue(
	category: string,
	groupId: string,
	contextElement: SnowplowContext | undefined,
	additionalContexts: SnowplowContext[]
) {
	queues.push([category, groupId, contextElement, additionalContexts]);
}

/** Sends the impression event with the batched contexts for the current category. */
function sendGroupImpressionEvent(group: GroupImpression) {
	if (!group.elements || group.elements.size === 0) return;
	const contexts = [...group.elements.values()].flat().concat(...group.additionalContexts.values());
	TrackingHelper.trackEvent(group.category, { ...properties }, contexts);
}

/**
 * Track a large number of impression events together.
 * Avoid using this directly, instead use it to create impression
 * tracking composables for specific kinds of elements.
 */
export function useBatchImpressionTracking<T>({
	category,
	toContext,
	observableOptions = mrcObservableOptions,
	trackingInterval = 1000, // @deprecated
	additionalContexts = [] as SnowplowContext[],
	groupId = '',
	onVisibilityCallback,
}: UseBatchImpressionTrackingOptions<T> & BatchImpressionTrackingOptions) {
	const instance = getCurrentInstance();
	function onIntersection(element: T, isVisible: boolean) {
		// first we only gather, later we will de-duplicate
		if (isVisible) {
			onVisibilityCallback?.(element);
			// we can safely add additionalContexts to all queue entries separately, additionalContexts they get de-duplicated per group
			addQueue(category, groupId, toContext(element) as SnowplowContext, toValue(additionalContexts));
		}
		instance?.proxy.$emit('onVisibilityChange', isVisible);
	}

	/** Single property if you don't need to override options. */
	function onVisibilityChanged(element: T): VueObserveVisibilityOptions {
		return {
			...observableOptions,
			callback: (isVisible: boolean) => onIntersection(element, isVisible),
		};
	}

	return {
		/** Single property if you don't need to override options. */
		onVisibilityChanged,

		/** The exact callback called whenever visibility changes. */
		onIntersection,
	};
}
