var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('HiddenHorizontalContainer',[_c('div',{ref:"scroller",staticClass:"hidden-horizontal-scrollbar__items",class:{
			'block-mobile-swiping': _vm.blockMobileSwipe,
			'shift-scrollbar': _vm.shift,
			'hidden-horizontal-scrollbar__items--faded-end': _vm.fadedEdge && !_vm.isAtEnd,
			'hidden-horizontal-scrollbar__items--faded-start': _vm.fadedEdge && _vm.curScrollPos !== 0,
			'hidden-horizontal-scrollbar__items--faded': _vm.fadedEdge && !_vm.isAtStart && !_vm.isAtEnd,
		},on:{"scroll":_vm.scrolled}},[_vm._t("default")],2),(_vm.forceShowNav || (!_vm.isAtStart && !_vm.disabled && !_vm.hideNav && _vm.isScrollBarVisible))?_c('span',{staticClass:"hidden-horizontal-scrollbar__nav hidden-horizontal-scrollbar__nav--start",class:{
			'hidden-horizontal-scrollbar__nav--force': _vm.forceShowNav && !_vm.isAtStart,
			'hidden-horizontal-scrollbar__nav--alt': _vm.altNav,
		},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.scrollToEnd.apply(null, arguments)}}},[_c('FontAwesomeIcon',{attrs:{"icon":"chevron-left"}})],1):_vm._e(),(_vm.forceShowNav || ((!_vm.allItemsLoaded || !_vm.isAtEnd) && !_vm.disabled && !_vm.hideNav && _vm.isScrollBarVisible))?_c('span',{staticClass:"hidden-horizontal-scrollbar__nav hidden-horizontal-scrollbar__nav--end",class:{
			'hidden-horizontal-scrollbar__nav--force': _vm.forceShowNav && !_vm.isAtEnd,
			'hidden-horizontal-scrollbar__nav--alt': _vm.altNav,
		},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.scrollToStart.apply(null, arguments)}}},[_c('FontAwesomeIcon',{attrs:{"icon":"chevron-right"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }