
import { Vue, Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import type { WebLocale } from '@/enums/web-locale';
import { getTitleContextEntityId } from '@/helpers/entity-helper';

const Language = namespace('language');

@Component({
	name: 'TitleDetailLink',
})
export default class TitleDetailLink extends Vue {
	@Prop() title: any;
	@Prop() offer: any;
	@Prop({ required: true }) fullPath: string;

	@Language.State webLocale: WebLocale;

	// this function is only executed with the `exact` modifier, so we can cmd + click on it to open a link in a new tab.
	navigate(evt: MouseEvent, parent: any, fullPath: string) {
		evt.preventDefault();
		parent.$router.push({ path: fullPath });
	}

	// don't use `async` modifier.
	// it will stop vue's (click) event bubbling when used together with dynamic imports.
	onClick(evt: Event, title: any, fullPath: string) {
		// open up modal if:
		// - title doesn't have a fullPath. that means it exists but doesn't have an URL in this country
		if (title && !fullPath) {
			evt.preventDefault();

			// switching to promises instead of async.
			// otherwise the (click) event bubbling will be paused and resumed after the modal has been closed again.
			Promise.all([import('@/components/modals/TitleDetailModal.vue'), import('@/helpers/modal-helper')]).then(
				async ([{ default: TitleDetailModal }, { ModalHelper, getCtr }]) => {
					// close other open modals
					const ctrl = await getCtr();
					const modal = (await ctrl.getTop()) as HTMLIonModalElement;
					modal?.dismiss();
					// open title detail modal
					ModalHelper.openModal(
						TitleDetailModal,
						{
							entityId: getTitleContextEntityId(title.objectType, title.objectId, 'TitleDetailLink'),
							titleName: title.content.title,
							releaseYear: title.content.originalReleaseYear,
						},
						{ cssClass: 'jw-title-detail-modal' }
					);
				}
			);
		}

		return undefined;
	}

	isExternalLink(fullPath: string) {
		return /^(http|https|www)/i.test(fullPath);
	}
}
