import { defineComponent, inject } from 'vue';

import type {
	VisibilityChangedPayload,
	BatchImpressionTrackingOptions,
} from '@/helpers/composables/useBatchImpressionTracking';
import { useBatchImpressionTracking } from '@/helpers/composables/useBatchImpressionTracking';

import { SraEventAction, type ObjectType, type SraPlacement } from '@/@types/graphql-types';
import type { TitleContentType } from '@/interfaces/snowplow/title-context';
import { SnowplowTitleContextGraphql } from '@/helpers/tracking/providers/snowplow-contexts';
import { getSrPlacement, SR_PROVIDER_KEY } from '@/components/sponsored-recommendations/useSponsoredRec';
import { useBackendTracking } from './useBackendTracking';

export type PosterVisibilityChangedPayload = VisibilityChangedPayload<any>;
export interface ImpressionTrackingPosterDetails {
	titleId: number;
	entityId?: string;
	objectType: ObjectType;
	seasonNumber?: number;
	episodeNumber?: number;
	contentType?: TitleContentType;
	onVisibilityCallback?: void;
}

export function usePosterImpressionTracking(options: BatchImpressionTrackingOptions) {
	const { trackSraEvent } = useBackendTracking();
	const placement = inject<SraPlacement | null>(SR_PROVIDER_KEY, null);

	function onVisibilityCallback({ entityId }: ImpressionTrackingPosterDetails) {
		const sponsoredAd = getSrPlacement(placement!);

		if (!placement || sponsoredAd?.campaign == null || sponsoredAd.bidId == null) return;

		if (sponsoredAd.holdoutGroup && sponsoredAd.campaign?.node?.nodeId === entityId) {
			trackSraEvent({ action: SraEventAction.Impression, sponsoredAd });
		}
	}

	return useBatchImpressionTracking<ImpressionTrackingPosterDetails>({
		category: 'title',
		toContext: getPosterImpressionContext,
		observableOptions: options.observableOptions,
		trackingInterval: options.trackingInterval,
		additionalContexts: options.additionalContexts,
		onVisibilityCallback,
	});
}

/** Renderless version */
export default defineComponent({
	name: 'UsePosterImpressionTracking',
	props: ['observableOptions', 'trackingInterval', 'additionalContexts'],
	setup(props: BatchImpressionTrackingOptions, { slots }) {
		return () => slots.default?.(usePosterImpressionTracking(props)) ?? slots[0];
	},
});

function getPosterImpressionContext({
	titleId,
	objectType,
	seasonNumber,
	episodeNumber,
	contentType,
}: ImpressionTrackingPosterDetails): SnowplowTitleContextGraphql {
	return new SnowplowTitleContextGraphql(titleId, objectType, seasonNumber, episodeNumber, contentType);
}
