import { computed, InjectionKey, provide } from 'vue';

import type { CountryCode } from '@/helpers/geo-location-helper';
import { SponsoredRecommendationsInput, SraPlacement } from '@/@types/graphql-types';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

import { getVm } from '@/helpers/vm-helper';
import { ObjectType, SraFormat } from '@/@types/graphql-types';
import { Platform } from '@/@types/graphql-types';
import { TrackingAppId, ApplicationContext } from '@/helpers/tracking/tracking-helper';
import { useLanguageStore } from '@/helpers/composables/useStores';
import { WebLocale } from '@/enums/web-locale';

interface UseSponsoredRecOptions {
	pageType: string;
	placement: SraPlacement;
	testingMode?: boolean;
}

const FORCE_SR_QUERY = 'sr' as const;
const FORCE_HOLDOUT_QUERY = 'holdout' as const;
const GEO_COUNTRY_QUERY = 'geo_country' as const;
export const SR_PROVIDER_KEY = Symbol() as InjectionKey<SraPlacement>;

export const supportedFormats = [SraFormat.Image, SraFormat.Video];
export const supportedObjectTypes = [
	ObjectType.Movie,
	ObjectType.Show,
	ObjectType.GenericTitleList,
	ObjectType.ShowSeason,
];

const recordSRPlacement = new Map<SraPlacement, SponsoredAdFragment>();

const paramToString = <T = string>(param: T[] | T) => (Array.isArray(param) ? param[0] : param) ?? null;

export function useSponsoredRec({ pageType, placement, testingMode = false }: UseSponsoredRecOptions) {
	const { language, country } = useLanguageStore();
	provide(SR_PROVIDER_KEY, placement);

	const queryGeoCountry = computed(() => paramToString<WebLocale>(getVm().$route.query[GEO_COUNTRY_QUERY]));

	/**
	 * Geo-country should be managed on SR, by checking the user-agent.
	 * This function instead will help us to manually set and debug geoCountry.
	 */
	const geoCountry = computed<CountryCode | undefined>(() => {
		if (!process.client) {
			return undefined;
		}

		if (queryGeoCountry.value && queryGeoCountry.value in WebLocale) {
			return queryGeoCountry.value.toUpperCase() as CountryCode;
		}

		return undefined;
	});

	/**
	 * If there is a query parameter 'sr' in the URL with the campaign name
	 * Se force the SR campaign to show
	 * However, the user needs to be in the correct country/platform/placement
	 * E.g. https://www.justwatch.com/uk?sr=WICKED_LITTLE_LETTERS_BURST3_GB_SRA
	 */
	const queryCampaignName = computed(() => paramToString<string>(getVm().$route.query[FORCE_SR_QUERY]));

	const forceHoldoutGroup = computed(
		() => paramToString<string>(getVm().$route.query[FORCE_HOLDOUT_QUERY]) === 'true'
	);

	const allowSponsoredRecommendations = computed<SponsoredRecommendationsInput>(() => ({
		pageType,
		placement,
		language: language.value,
		country: country.value,
		geoCountry: geoCountry.value,
		applicationContext: ApplicationContext, // now with appContext
		appId: TrackingAppId,
		platform: Platform.Web,
		// Have every SR Placement supoort every format
		supportedFormats,
		supportedObjectTypes,

		testingMode: testingMode || queryCampaignName.value != null || queryGeoCountry.value != null,
		testingModeCampaignName: queryCampaignName.value,
		testingModeForceHoldoutGroup: !!forceHoldoutGroup.value,
	}));

	return {
		allowSponsoredRecommendations,
		saveSrPlacement: (sponsoredAd: SponsoredAdFragment | undefined) => {
			if (!sponsoredAd) return;
			recordSRPlacement.set(placement, sponsoredAd);
		},
	};
}

export const getSrPlacement = (placement: SraPlacement): SponsoredAdFragment | undefined => {
	return recordSRPlacement.get(placement);
};
export const getSrPlacementFromBidId = (bidId: string): SraPlacement | undefined => {
	let placement: SraPlacement | undefined;
	recordSRPlacement.forEach((value, key) => {
		if (bidId === value.bidId) {
			placement = key;
		}
	});
	return placement;
};

export const getSrFromBidId = (
	bidId: string
): { placement: SraPlacement | undefined; srFragment: SponsoredAdFragment | undefined } => {
	let placement: SraPlacement | undefined;
	let srFragment: SponsoredAdFragment | undefined;
	recordSRPlacement.forEach((value, key) => {
		if (bidId === value.bidId) {
			placement = key;
			srFragment = value;
		}
	});
	return { placement, srFragment };
};
