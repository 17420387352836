
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import GlobalCards from '@/components/home/global/GlobalCards.vue';
import GlobalHero from '@/components/home/global/GlobalHero.vue';
import GlobalTitles from '@/components/home/global/GlobalTitles.vue';
import ProvideGlobalTitleData from '@/components/home/global/ProvideGlobalTitleData.vue';

const CountriesList = () =>
	import(/* webpackChunkName: "countries-list" */ '@/components/countries-list/CountriesList.vue');

const CallToAction = () => import(/* webpackChunkName: "call-to-action" */ '@/components/home/global/CallToAction.vue');

const GlobalLargeSpotlight = () =>
	import(/* webpackChunkName: "global-large-spotlight" */ '@/components/home/global/GlobalLargeSpotlight.vue');

const GlobalSmallSpotlight = () =>
	import(/* webpackChunkName: "global-small-spotlight" */ '@/components/home/global/GlobalSmallSpotlight.vue');

const GlobalProviderSpotlight = () =>
	import(/* webpackChunkName: "spotlight-provider" */ '@/components/home/global/GlobalProviderSpotlight.vue');
const GlobalSports = () =>
	import(/* webpackChunkName: "spotlight-sport" */ '@/components/home/global/GlobalSports.vue');

import { COUNTRY_EXCEPTIONS, type WebLocales } from '@/constants/web-locales.constant';
import { isSportSupported } from '@/enums/sports';
import type { WebLocale } from '@/enums/web-locale';
import type Provider from '@/interfaces/provider';

// Hardcoded value for now @liam.best
const spotlightProvider = {
	name: 'atp',
	packageId: 350,
};

const Language = namespace('language');

@Component({
	name: 'Global',
	components: {
		GlobalHero,
		GlobalCards,
		GlobalSports,
		ProvideGlobalTitleData,
		GlobalTitles,
		GlobalLargeSpotlight,
		GlobalSmallSpotlight,
		GlobalProviderSpotlight,
		CountriesList,
		CallToAction,
	},
})
export default class Global extends Vue {
	spotlightProvider = spotlightProvider;
	@Prop({ required: true }) country: string;
	@Prop({ required: true }) language: string;
	@Prop({ required: true }) providersByShortName: Record<string, Provider>;

	@Language.Getter activeLocalesList: WebLocales;

	get ASSETS_DIR(): string {
		return `/${ASSETS_DIR}`;
	}

	get webLocale(): WebLocale {
		return this.country.toLowerCase() as WebLocale;
	}

	get hasSports() {
		const isException = COUNTRY_EXCEPTIONS[this.webLocale];
		if (isException) {
			return isSportSupported(isException);
		}
		return isSportSupported(this.webLocale);
	}

	get isProviderSpotlightAvailable() {
		return !!this.providersByShortName[spotlightProvider.name];
	}
}
